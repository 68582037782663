import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import { ROUTES } from 'constants/routes';
import { X_PATH } from 'constants/xPath';
import useAuthentication from 'hooks/useAuthentication';
import AccountMenu from '#web-components/components/AccountMenu';
import MenuItem from '#web-components/components/MenuList/components/MenuItem';

import styles from './Menu.styles';

const useStyles = makeStyles(styles, { name: 'Menu' });

export default function Menu() {
  const authentication = useAuthentication();
  const history = useHistory();
  const userInfo = authentication.info;
  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleLogout = useCallback((): void => {
    authentication.logout();
  }, [authentication]);

  const handleNavigateTo = useCallback((path: string) => () => {
    history.push(path);
  }, [history]);

  if (!(authentication.authenticated)) {
    return null;
  }

  return (
    <AccountMenu
      givenName={userInfo?.given_name}
      familyName={userInfo?.family_name}
      userName={userInfo?.name}
      xpathUserInfo={X_PATH.headerUserInfo}
    >
      <MenuItem
        onClick={handleNavigateTo(ROUTES.PROFILE)}
      >
        {t('components.header.profile')}
      </MenuItem>
      <hr className={classes.line} />
      <MenuItem
        data-xpath={X_PATH.logout}
        onClick={handleLogout}
      >
        {t('components.header.logout')}
      </MenuItem>
    </AccountMenu>
  );
}
