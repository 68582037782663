import { createStyles } from '@material-ui/core/styles';
import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  dialogContent: {
    marginTop: `${spacing * 3}px`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& button': {
      textTransform: 'capitalize',
    },
  },
});

export default styles;
