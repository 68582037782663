import { createSelector } from 'reselect';

import type { RootState } from 'store/rootReducer';
import { formatTaskDataToFormSubmission } from 'utils/formData';
import {
  createAsyncActionErrorSelector,
  createAsyncActionIsLoadingSelector,
  createAsyncActionValidationErrorsSelector,
} from '../asyncAction';
import {
  ProcessDefinitionsState,
  startProcessRequest,
  getProcessDefinitionRequest,
  getProcessDefinitionFormRequest,
  startProcessWithFormRequest,
  getGroupedProcessDefinitionsRequest,
} from './slice';

export const selectProcessDefinitionsState = (state: RootState): ProcessDefinitionsState => state.processDefinitions;

export const selectProcessDefinitions = createSelector(
  selectProcessDefinitionsState,
  (processDefinitionsState) => processDefinitionsState.list,
);
export const selectGroupedProcessDefinitions = createSelector(
  selectProcessDefinitionsState,
  (processDefinitionsState) => processDefinitionsState.groupData,
);

export const selectProcessDefinitionsIsLoading = createSelector(
  createAsyncActionIsLoadingSelector(getGroupedProcessDefinitionsRequest.type),
  createAsyncActionIsLoadingSelector(startProcessRequest.type),
  (getProcessLoading, startProcessLoading) => getProcessLoading || startProcessLoading,
);
export const selectProcessDefinitionsError = (
  createAsyncActionErrorSelector(getGroupedProcessDefinitionsRequest.type)
);

export const selectProcessDefinitionForm = createSelector(
  selectProcessDefinitionsState,
  (processDefinitionsState) => processDefinitionsState.form,
);

export const selectProcessDefinitionError = (
  createAsyncActionErrorSelector(getProcessDefinitionRequest.type)
);
export const selectProcessDefinitionFormError = (
  createAsyncActionErrorSelector(getProcessDefinitionFormRequest.type)
);

export const selectProcessDefinitionLoading = createSelector(
  createAsyncActionIsLoadingSelector(getProcessDefinitionFormRequest.type),
  createAsyncActionIsLoadingSelector(getProcessDefinitionRequest.type),
  createAsyncActionIsLoadingSelector(startProcessWithFormRequest.type),
  (
    formLoading,
    processDefinitionLoading,
    processWithFormStarting,
  ) => formLoading || processDefinitionLoading || processWithFormStarting,
);

export const selectProcessDefinitionFormValidationErrors = (
  createAsyncActionValidationErrorsSelector(startProcessWithFormRequest.type)
);

export const selectStartFormSubmissionData = createSelector(
  selectProcessDefinitionsState,
  (form) => formatTaskDataToFormSubmission(form.formData?.data),
);
