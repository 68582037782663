import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import StandardLayout from 'components/Layouts/Standard';
import { makeStyles, TablePagination } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';

import {
  acknowledgeNotificationRequest,
  getNotificationListRequest,
  selectUserNotifications,
  selectUserNotificationsError,
  selectUserNotificationsIsLoading,
  selectUserNotificationsPaginationParams,
} from 'store/userNotifications';
import CriticalError from 'components/CriticalError';
import { X_PATH } from 'constants/xPath';
import { Notification } from 'types/notification';
import { ERROR_TYPE } from '#shared/types/common';
import { ROWS_PER_PAGE_OPTIONS } from '#web-components/components/Table';
import TablePaginationActions from '#web-components/components/Table/components/TablePaginationActions';

import NotificationsEmptyPlaceholder from './components/NotificationsEmptyPlaceholder';
import NotificationItem from './components/NotificationItem/NotificationItem';
import styles from './NotificationListPage.styles';

const useStyles = makeStyles(styles, { name: 'NotificationListPage' });

export default function NotificationListPage() {
  const { t } = useTranslation('notificationListPage');
  const classes = useStyles();
  const dispatch = useDispatch();

  const userNotificationsIsLoading = useSelector(selectUserNotificationsIsLoading);
  const userNotificationsError = useSelector(selectUserNotificationsError);

  const notifications = useSelector(selectUserNotifications);
  const paginationParams = useSelector(selectUserNotificationsPaginationParams);

  useEffect(() => {
    dispatch(getNotificationListRequest({
      paginationParams: { page: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0] },
      isInitialRequest: true,
    }));
  }, [dispatch]);

  const handleOpenNotification = useCallback((notification: Notification) => {
    if (!notification.isAcknowledged) {
      dispatch(acknowledgeNotificationRequest({ notificationId: notification.id }));
    }
  }, [dispatch]);

  const renderNotifications = () => {
    if (userNotificationsError && userNotificationsError.type === ERROR_TYPE.COMPONENT) {
      return (<CriticalError error={userNotificationsError} />);
    }

    if (!notifications.length && !userNotificationsIsLoading) {
      return (
        <NotificationsEmptyPlaceholder />
      );
    }

    const hasPagination = notifications.length > ROWS_PER_PAGE_OPTIONS[0] || paginationParams.page > 0;
    const hasNextPage = notifications.length > ROWS_PER_PAGE_OPTIONS[0];
    const totalCount = paginationParams.page * ROWS_PER_PAGE_OPTIONS[0] + notifications.length;

    const handlePageChange = (
      event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent> | null,
      page: number,
    ) => {
      dispatch(getNotificationListRequest({ paginationParams: { page, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0] } }));
    };

    return (
      <div className={classes.notificationsContainer}>
        {notifications.slice(0, ROWS_PER_PAGE_OPTIONS[0]).map((notification) => (
          <NotificationItem
            notification={notification}
            key={notification.id}
            onOpen={handleOpenNotification}
          />
        ))}
        {hasPagination && (
          <TablePagination
            component="div"
            count={totalCount}
            page={paginationParams.page}
            rowsPerPage={ROWS_PER_PAGE_OPTIONS[0]}
            rowsPerPageOptions={[]}
            onChangePage={handlePageChange}
            labelDisplayedRows={(params) => (t('notification.paginationNoCount', params))}
            ActionsComponent={(actions: TablePaginationActionsProps) => TablePaginationActions(
              {
                ...actions,
                'data-xpath': {
                  tablePageCountSelect: X_PATH.tablePageCountSelect,
                  tableFirstPage: X_PATH.tableFirstPage,
                  tablePrevPage: X_PATH.tablePrevPage,
                  tableNextPage: X_PATH.tableNextPage,
                  tableLastPage: X_PATH.tableLastPage,
                },
                ariaLabel: {
                  firstPage: t('common:components.table.aria.firstPage'),
                  prevPage: t('common:components.table.aria.prevPage'),
                  nextPage: t('common:components.table.aria.nextPage'),
                  lastPage: t('common:components.table.aria.lastPage'),
                },
                isTotalKnown: false,
                disableNextPage: !hasNextPage,
              },
            )}
          />
        )}
      </div>
    );
  };

  return (
    <StandardLayout
      title={t('page.pageTitle')}
      description={t('common:appSections.myNotifications.description')}
      isLoading={userNotificationsIsLoading}
    >
      {renderNotifications()}
    </StandardLayout>
  );
}
