import {
  useCallback, useState, useEffect, useRef,
} from 'react';
import { createTimeString } from '#web-components/utils';

export default function useTimer(timeout: number, locale: string) {
  const [currentTime, changeCurrentTime] = useState(timeout);
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const clear = useCallback(() => {
    changeCurrentTime(timeout);
  }, [timeout]);
  const stop = useCallback(() => {
    changeCurrentTime(0);
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);

  useEffect(() => {
    timer.current = setTimeout(() => {
      if (currentTime !== 0) {
        changeCurrentTime(currentTime - 1);
      }
    }, 1000);
  }, [currentTime, changeCurrentTime]);

  return {
    time: currentTime,
    timeString: createTimeString(currentTime, {
      locale,
      showSeconds: true,
      showMinutes: true,
    }),
    clear,
    stop,
  };
}
