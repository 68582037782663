import {
  PopperPlacementType,
} from '@material-ui/core';
import { ButtonProps } from '#web-components/components/Button/Button';
import { IconButtonProps } from '#web-components/components/IconButton/IconButton';

export interface PopperProps {
  children: React.ReactNode;
  anchorEl: { current?: HTMLElement | null };
  open: boolean;
  onClose: (open: boolean) => void
  placement?: PopperPlacementType;
  timeout?: number;
  fullWidth?: boolean;
  zIndex?: number;
  disablePortal?: boolean;
}

export type PopperButtonProps = Pick<PopperProps, 'children' | 'placement' | 'timeout' | 'zIndex' | 'disablePortal'> & {
  buttonProps: ButtonProps & IconButtonProps,
  buttonType?: ButtonType,
};

export enum ButtonType {
  default = 'default',
  icon = 'icon',
}
