import { api } from 'api';
import { DOCUMENTS_MANAGEMENT_API_APPENDIX } from 'constants/baseUrl';

type MetadataSearchItem = {
  id: string,
  fieldName: string,
};

export const getMetadataByIds = (searchMap: MetadataSearchItem[], processInstanceId: string, taskId: string) => {
  return api.post$(`${DOCUMENTS_MANAGEMENT_API_APPENDIX}/${processInstanceId}/${taskId}/search`, {
    documents: searchMap,
  });
};
