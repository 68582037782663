import React from 'react';
import { WithStyles } from '@material-ui/core';
import Modal from '#web-components/components/Modal';
import Typography from '#web-components/components/Typography';
import { ModalProps } from '#web-components/components/Modal/Modal';

import DialogTitleConfirm from './components/DialogTitleConfirm';
import styles from './ConfirmModal.styles';

export type ConfirmModalProps = Pick<
ModalProps, 'title' | 'isOpen' | 'submitText' | 'cancelText' | 'onSubmit' | 'onOpenChange'
> & {
  confirmationText: string;
  icon?: React.ReactNode;
};

export interface Props extends WithStyles<typeof styles>, ConfirmModalProps { }

export default class ConfirmModal extends React.Component<Props & WithStyles<typeof styles>> {
  render() {
    const {
      isOpen,
      onSubmit,
      onOpenChange,
      classes,
      title,
      submitText,
      cancelText,
      confirmationText,
      icon,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        submitText={submitText}
        cancelText={cancelText}
        onOpenChange={onOpenChange}
        onSubmit={onSubmit}
        classes={classes}
        hasCloseBtn={false}
        title={<DialogTitleConfirm title={title} icon={icon} />}
      >
        <Typography variant="bodyText">{confirmationText}</Typography>
      </Modal>
    );
  }
}
