import { Action } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import {
  concatMap, filter, mergeMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { push } from 'connected-react-router';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';

import * as api from 'api/taskManagement';
import * as formApi from 'api/formInteraction';
import * as documentsApi from 'api/documentsManagement';

import { ROUTES, TAB_ROUTES } from 'constants/routes';
import { formatFormSubmissionToTaskData, getValidationErrors } from 'utils/formData';
import { UserTaskCompleteResponse, UserTaskIdsResponse } from '#shared/types/task';
import {
  conflictNotificationErrorProps,
  formNotFoundErrorProps,
  permissionNotificationErrorProps,
  tryAgainNotificationErrorProps,
} from 'constants/errorProps';
import { ErrorInfo } from '#shared/types/common';
import { FileMetadata } from 'types/form';
import { ALL_TASKS_FILTER_PARAMS } from 'constants/tableFiltering';
import { notify, STATUSES } from 'reapop';
import i18n from 'localization';
import { prepareFileSubmission, fillFilesMetadata, getFileIdsWithoutMetadata } from '#web-components/utils';
import {
  getCriticalErrorProps, isValidationError, catchError, isConflictError, isPermissionError, getNotificationErrorProps,
} from '#shared/utils/apiHelpers';
import { MetadataSearchItem } from '#web-components/components/Form/types';

import type { RootState } from '../rootReducer';
import { selectUserTaskForm } from './selectors';

import {
  getTaskRequest,
  getTaskSuccess,
  getTaskError,
  getFormSuccess,
  completeTaskRequest,
  completeTaskError,
  completeTaskSuccess,
  signTaskRequest,
  signTaskError,
  signTaskSuccess,
  getPendingTaskRequest,
  getPendingTaskError,
  getPendingTaskSuccess,
  saveTaskRequest,
  saveTaskSuccess,
  saveTaskError,
} from './slice';

export const getFormByTaskIdEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    filter(getTaskRequest.match),
    mergeMap(({ payload }) => {
      return api.getTaskById(payload).pipe(
        concatMap(({ response: taskResponse }) => formApi
          .getForm(taskResponse.formKey)
          .pipe(
            mergeMap(({ response: formResponse }) => {
              const fileIds = getFileIdsWithoutMetadata(formResponse, taskResponse.data);
              if (fileIds && fileIds.length > 0) {
                return documentsApi.getMetadataByIds(
                  fileIds as MetadataSearchItem[],
                  taskResponse.processInstanceId,
                  taskResponse.id,
                )
                  .pipe(
                    mergeMap(({ response: metadataResponse }) => {
                      const metadata = metadataResponse as FileMetadata[];
                      const data = fillFilesMetadata(metadata, taskResponse.data, formResponse);
                      const filledTask = {
                        ...taskResponse,
                        data,
                      };
                      return of(getTaskSuccess(filledTask), getFormSuccess(formResponse));
                    }),
                  );
              }
              return of(getTaskSuccess(taskResponse), getFormSuccess(formResponse));
            }),
            catchError((serverResponse) => of(getTaskError(
              getCriticalErrorProps({ serverResponse, errorProps: formNotFoundErrorProps }),
            ))),
          )),
        catchError((serverResponse) => of(getTaskError(getCriticalErrorProps({ serverResponse })))),
      );
    }),
  );
};

const getTaskSubmitSuccessActions = (
  rootProcessInstanceId: string,
  successAction: ActionCreatorWithoutPayload,
  processStatus: UserTaskCompleteResponse,
) => {
  const getResultObservable = (route: string) => of(
    successAction(),
    push(route, { forceLeave: true }),
  );

  if (processStatus.rootProcessInstanceEnded) {
    return getResultObservable(TAB_ROUTES.PROCESS_INSTANCE_LIST_ENDED);
  }

  return api.getPendingTaskIdsList({ ...ALL_TASKS_FILTER_PARAMS, maxResults: 2 }, rootProcessInstanceId).pipe(
    mergeMap(({ response: newTasks } : { response: UserTaskIdsResponse[] }) => {
      if (newTasks.length) {
        const taskId = newTasks[0].id;
        const route = ROUTES.USER_TASK.replace(':taskId', taskId);
        return getResultObservable(route);
      }

      return getResultObservable(TAB_ROUTES.PROCESS_INSTANCE_LIST_ACTIVE);
    }),
  );
};

export const completeTaskEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(completeTaskRequest.match),
      mergeMap(({ payload }) => {
        const form = selectUserTaskForm(state$.value);
        const preparedFormData = prepareFileSubmission(form?.components || [], payload.formData);
        return api.completeTask(payload.taskId, formatFormSubmissionToTaskData(preparedFormData)).pipe(
          mergeMap(({ response } : { response: UserTaskCompleteResponse }) => {
            const { rootProcessInstanceId } = response;
            return getTaskSubmitSuccessActions(rootProcessInstanceId, completeTaskSuccess, response);
          }),
          catchError((response) => {
            let errors: Array<ErrorInfo> = [];
            if (isValidationError(response)) {
              errors = getValidationErrors(response, form);
            }

            if (isConflictError(response)) {
              errors = [
                getNotificationErrorProps(response.response, conflictNotificationErrorProps),
              ];
            }

            if (isPermissionError(response)) {
              errors = [getNotificationErrorProps(response.response, permissionNotificationErrorProps)];
            }

            return of(completeTaskError(errors.length ? errors : [
              getNotificationErrorProps(response.response, tryAgainNotificationErrorProps),
            ]));
          }),
        );
      }),
    );
};

export const completeSignTaskEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(signTaskRequest.match),
      mergeMap(({ payload }) => {
        return api.completeSignTask(payload.taskId, payload.data).pipe(
          mergeMap(({ response } : { response: UserTaskCompleteResponse }) => {
            const { rootProcessInstanceId } = response;
            return getTaskSubmitSuccessActions(rootProcessInstanceId, signTaskSuccess, response);
          }),
          catchError((response) => {
            const form = selectUserTaskForm(state$.value);
            let errors: Array<ErrorInfo> = [];
            if (isValidationError(response)) {
              errors = getValidationErrors(response, form);
            }

            if (isConflictError(response)) {
              errors = [getNotificationErrorProps(response.response, conflictNotificationErrorProps)];
            }

            if (isPermissionError(response)) {
              errors = [getNotificationErrorProps(response.response, permissionNotificationErrorProps)];
            }

            return of(signTaskError(errors.length ? errors : [
              getNotificationErrorProps(response.response, tryAgainNotificationErrorProps),
            ]));
          }),
        );
      }),
    );
};

export const getPendingTaskEpic = (action$: ActionsObservable<Action>) => {
  return action$.pipe(
    filter(getPendingTaskRequest.match),
    mergeMap(({ payload }) => {
      const { processInstanceId } = payload;
      return api.getPendingTaskIdsList({ ...ALL_TASKS_FILTER_PARAMS, maxResults: 1 }, processInstanceId).pipe(
        mergeMap(({ response: newTasks }) => {
          const { id } = newTasks[0];
          const route = ROUTES.USER_TASK.replace(':taskId', id);
          return of(getPendingTaskSuccess(), push(route));
        }),
        catchError((serverResponse) => of(getPendingTaskError(getCriticalErrorProps({ serverResponse })))),
      );
    }),
  );
};

export const saveTaskEpic = (
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>,
) => {
  return action$
    .pipe(
      filter(saveTaskRequest.match),
      mergeMap(({ payload }) => {
        const form = selectUserTaskForm(state$.value);
        const preparedFormData = prepareFileSubmission(form?.components || [], { data: payload.data });
        return api.saveTask(payload.taskId, formatFormSubmissionToTaskData(preparedFormData)).pipe(
          mergeMap(() => {
            return of(
              saveTaskSuccess(payload.data),
              notify(i18n.t('formMessages.dataSavedSuccessfully'), STATUSES.success),
            );
          }),
          catchError((serverResponse) => {
            return of(saveTaskError(
              getNotificationErrorProps(serverResponse.response, tryAgainNotificationErrorProps),
            ));
          }),
        );
      }),
    );
};
