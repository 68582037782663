import React from 'react';
import {
  Theme, withStyles, WithStyles,
} from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import mapValues from 'lodash/mapValues';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';
import { Formio } from 'formiojs';

import type { EditGridComponent, FormComponent, FormSubmission } from '#web-components/components/Form/types';
import { FORMIO_EVENT } from '#web-components/components/Form/types';
import withFormioControl from '#web-components/components/Form/components/WithFormioControl';
import { FormControlError } from '#web-components/types/formControls';
import Form from '#web-components/components/Form/Form';
import FieldError from '#web-components/components/FieldError';
import {
  AriaLabel,
  ColumnDefinition,
  ListItem,
  Order,
} from '#web-components/types/table';
import Table from '#web-components/components/Table';
import Modal from '#web-components/components/Modal';
import Button, { ButtonVariants } from '#web-components/components/Button';
import Typography from '#web-components/components/Typography';
import PlusIcon from '#web-components/components/Icons/PlusIcon';
import TrashCanIcon from '#web-components/components/Icons/TrashCanIcon';
import InlineButton from '#web-components/components/InlineButton';
import DescriptionBox from '#web-components/components/DescriptionBox';
import { convertSubmissionData, transformComponents } from '#web-components/components/Form/utils';
import { ACTION_SELECTION_FIELD, FLEX_FIELD, SELECTION_FIELD } from '#web-components/components/Form/constants';
import Checkbox from '#web-components/components/FormControls/Checkbox';
import SearchInput from '#web-components/components/SearchInput';

import EditGridActions from './components/EditGridActions';
import styles from './CustomEditGrid.styles';

enum SelectionMode {
  edit,
  view,
}

interface Props extends WithStyles<typeof styles> {
  value: Array<Record<string, unknown>>;
  name: string;
  onChange: (value: unknown) => void;
  onExecuteActionOnSelection: (selectedItems: Array<Record<string, unknown>>, code: string) => void;
  component: EditGridComponent;
  error?: FormControlError;
  components: Array<FormComponent>;
  language: string;
  theme: Theme;
  disabled?: boolean;
  evalContext: Record<string, unknown>;
  prepareGridDisplayValue: (value: unknown, component: FormComponent) => unknown;
  textLabels: {
    view: string;
    add: string,
    save: string,
    edit: string,
    delete: string,
    cancel: string,
    emptyPlaceholder: string,
    editModalTitle: string,
    viewModalTitle: string,
    searchEmptyPlaceholder: string,
    deleteConfirmationTitle: string,
    deleteConfirmationYes: string,
    deleteConfirmationNo: string,
    rowsPerPage: string,
    paginationAriaLabel: AriaLabel,
    getDisplayedRowsLabel: (params: { from: number, to: number, count?: number }) => string;
    searchPlaceholder: string,
    searchLabel: string,
  };
}

interface State {
  isSelected: boolean;
  selectionMode: SelectionMode;
  itemUnderSelection?: Record<string, unknown>;
  indexUnderSelection?: number;
  indexUnderDeletion: number | null;
  searchString: string;
}

interface ComponentClass {
  path: string,
  getValueAsString: (value: unknown) => string,
}

const MIN_SEARCH_LENGTH = 3;

class EditGridAdapter extends React.Component<Props, State> {
  state = {
    isSelected: false,
    selectionMode: SelectionMode.edit,
    indexUnderDeletion: null,
    searchString: '',
  } as State;

  forms: Array<{ components: Array<ComponentClass>, submission?: Record<string, unknown> }> = [];

  saveButtonComponent = {
    type: 'button',
    label: this.props.component.saveRow || this.props.textLabels.save,
    key: 'submit',
    size: 'md',
    block: false,
    action: 'submit',
    disableOnInvalid: true,
    theme: 'primary',
    input: true,
    unique: false,
    hidden: false,
    clearOnHide: true,
    refreshOn: '',
    redrawOn: '',
    tableView: false,
    modalEdit: false,
    labelPosition: 'top',
    description: '',
    errorLabel: '',
    tooltip: '',
    hideLabel: false,
    tabindex: '',
    disabled: false,
    autofocus: false,
    customDefaultValue: '',
    calculateValue: '',
    widget: {
      type: 'input',
    },
    attributes: {},
    validateOn: 'change',
    validate: {
      required: false,
      custom: '',
      customPrivate: false,
      strictDateValidation: false,
      multiple: false,
      unique: false,
    },
    components: null,
    properties: {},
    id: 'ey7dq4',
  } as FormComponent;

  cancelButtonComponent = {
    type: 'button',
    label: this.props.component.removeRowText || this.props.textLabels.cancel,
    key: 'cancel',
    size: 'md',
    block: false,
    action: 'event',
    event: FORMIO_EVENT.CANCEL,
    disableOnInvalid: false,
    theme: 'secondary',
    input: true,
    hidden: false,
    clearOnHide: true,
    refreshOn: '',
    redrawOn: '',
    tableView: false,
    modalEdit: false,
    labelPosition: 'top',
    description: '',
    errorLabel: '',
    tooltip: '',
    hideLabel: false,
    tabindex: '',
    disabled: false,
    autofocus: false,
    customDefaultValue: '',
    calculateValue: '',
    widget: {
      type: 'input',
    },
    attributes: {},
    validateOn: 'change',
    validate: {
      required: false,
      custom: '',
      customPrivate: false,
      strictDateValidation: false,
      multiple: false,
      unique: false,
    },
    components: null,
    properties: {},
    id: 'ey7dq4',
  } as FormComponent;

  componentDidMount() {
    const { value } = this.props;
    if (value?.length) {
      this.init();
    }
  }

  componentDidUpdate(prevProps: { value: unknown; }) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!isEqual(this.props.value, prevProps.value)) {
      this.init();
    }
  }

  /*
  This function creates instances of formio forms for each table row,
  so we can later use them to get component instance of each cell.
   */
  init = () => {
    const {
      components,
      value,
      theme,
      onChange,
      language,
      component,
    } = this.props;
    const editGridValue = value || [];

    this.forms = [];

    if (!component.optimizedRendering) {
      Promise.all(
        editGridValue.map((data, index) => (
          Formio.createForm(
            document.getElementById(this.getFormId(index)),
            { components, submission: { data } },
            { theme, language },
          )
            .then((form: { components: ComponentClass[]; }) => {
              this.forms[index] = form;
              this.forms[index].submission = { data };
            }))),
      ).then(() => {
        const formioValue = this.forms.map((form) => form.submission?.data);
        if (!isEqual(formioValue, editGridValue)) {
          // Formio could have set default values for it's components. We need to reflect that in editGrid value
          onChange(formioValue);
        } else {
          this.forceUpdate();
        }
      });
    }
  };

  onRowSubmit = (data: FormSubmission, index?: number) => {
    const { onChange, value } = this.props;

    if (isNumber(index)) {
      onChange([...value.slice(0, index), data.data, ...value.slice(index + 1)]);
    } else {
      onChange([...value, data.data]);
    }

    this.setState({
      isSelected: false,
    });
  };

  onAction = (index: number) => (code: string) => {
    const { value, onExecuteActionOnSelection } = this.props;
    const newValue = value.map((valueItem, valueIndex) => omit({
      ...valueItem,
      [ACTION_SELECTION_FIELD]: valueIndex === index,
    }, SELECTION_FIELD));

    onExecuteActionOnSelection(newValue, code);
  };

  onAdd = () => {
    this.setState({
      isSelected: true,
      selectionMode: SelectionMode.edit,
      itemUnderSelection: {},
      indexUnderSelection: undefined,
    });
  };

  onSelect = (index: number, mode: SelectionMode) => () => {
    const { value } = this.props;
    const item = value[index];
    this.setState({
      isSelected: true,
      selectionMode: mode,
      itemUnderSelection: item,
      indexUnderSelection: index,
    });
  };

  onDelete = (index: number) => () => {
    this.setState({
      indexUnderDeletion: index,
    });
  };

  onDeleteConfirmation = () => {
    const { onChange, value } = this.props;
    const { indexUnderDeletion } = this.state;

    if (isNumber(indexUnderDeletion)) {
      onChange([...value.slice(0, indexUnderDeletion), ...value.slice(indexUnderDeletion + 1)]);
    }
    this.onDeleteConfirmationChange(false);
  };

  onSelectionChange = (isSelected: boolean) => {
    this.setState({
      isSelected,
    });
  };

  onDeleteConfirmationChange = (isModalOpen?: boolean) => {
    if (!isModalOpen) {
      this.setState({
        indexUnderDeletion: null,
      });
    }
  };

  getStringValue = (index: number, key: string, data: unknown) => {
    return this.forms[index]?.components
      .find((component) => component.path === key)
      ?.getValueAsString(data);
  };

  formDisplayList = () => {
    const {
      value,
      component,
      components,
      prepareGridDisplayValue,
    } = this.props;
    const editGridValue = value || [];

    if (component.optimizedRendering) {
      return editGridValue.map((item, index) => ({
        id: index.toString(),
        ...convertSubmissionData(
          components,
          item,
          prepareGridDisplayValue,
        ),
      })) as Array<ListItem>;
    }

    return editGridValue
      .map((item, index) => ({
        ...mapValues(item, (data, key) => this.getStringValue(index, key, data)),
        id: index.toString(),
      } as ListItem));
  };

  onFormEvent = ({ type }: { type: string }) => {
    if (type === FORMIO_EVENT.CANCEL) {
      this.setState({
        isSelected: false,
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getFormId = (index: number) => `formio-form-${index}`;

  allowAddingItems = () => {
    const {
      value = [],
      component,
    } = this.props;
    const maxLength = component.validate?.maxLength;

    if (isNumber(maxLength) && value) {
      return value.length < maxLength;
    }

    return true;
  };

  allowRemovingItems = () => {
    const {
      value = [],
      component,
    } = this.props;
    const minLength = component.validate?.minLength;

    if (isNumber(minLength)) {
      return value.length > minLength;
    }

    return true;
  };

  getModalComponents = (components: Array<FormComponent>): Array<FormComponent> => {
    const { selectionMode } = this.state;

    return selectionMode === SelectionMode.edit
      ? [
        ...components,
        this.saveButtonComponent,
        this.cancelButtonComponent,
      ]
      : [
        ...transformComponents(components, (component) => ({
          ...component,
          disabled: true,
        })),
        this.cancelButtonComponent,
      ];
  };

  handleChangeSearch = (searchString: string) => {
    this.setState({ searchString });
  };

  render() {
    const {
      value,
      name,
      component,
      error,
      components,
      textLabels,
      classes,
      language,
      disabled,
      evalContext,
    } = this.props;
    const {
      isSelected,
      itemUnderSelection,
      indexUnderSelection,
      indexUnderDeletion,
      selectionMode,
      searchString,
    } = this.state;
    const editGridValue = value || [];

    const displayList = this.formDisplayList();
    const filteredDisplayList = displayList.filter((item) => {
      if (!component.quickSearch || (component.quickSearch && searchString.length < MIN_SEARCH_LENGTH)) {
        return true;
      }
      const invisibleComponentKeys = components
        .filter((c) => !c.tableView || c.hidden)
        .map((c) => c.key)
        .concat(['id', 'submit', 'cancel']);
      return Object.entries(item)
        .filter(([key]) => !invisibleComponentKeys.includes(key))
        .some(([, val]) => (val || '').toLowerCase().includes(searchString.toLowerCase()));
    });
    const columnDefinitions: ColumnDefinition[] = [
      ...(!component.multipleSelection ? [] : [{
        property: SELECTION_FIELD,
        title: '',
        sortable: false,
        width: '56px',
        // eslint-disable-next-line react/no-unstable-nested-components
        Component: ({ item }) => {
          const index = displayList.indexOf(item);
          const currentValueItem = editGridValue[index];
          return (
            <Checkbox
              id={get(item, 'id', '')}
              value={get(currentValueItem, SELECTION_FIELD, false) as boolean}
              onChange={(selected: boolean) => {
                this.onRowSubmit(
                  { data: { ...currentValueItem, [SELECTION_FIELD]: selected } },
                  index,
                );
              }}
              classes={{
                label: classes.selectionCheckboxLabel,
                root: classes.selectionCheckboxRoot,
              }}
            />
          );
        },
      } as ColumnDefinition]),
    ].concat(
      components
        .filter((c) => c.tableView && !c.hidden)
        .map((c) => ({
          property: c.key,
          title: c.label,
          width: c.columnWidth ? `${c.columnWidth}px` : '400px',
          ...(get(c, 'sortAsNumber', false) ? {
            sortFunction: (list, orderField, order) => {
              const groupedList = groupBy(list, (item) => {
                const propValue = get(item, orderField);
                const convertedToNumber = Number(propValue);

                return typeof (Number.isNaN(convertedToNumber) ? propValue : convertedToNumber);
              });
              const orderNumberIterator = (item: object) => Number(get(item, orderField));
              const orderedNumberItems = orderBy(get(groupedList, 'number'), orderNumberIterator, order);
              const orderedStringItems = orderBy(get(groupedList, 'string'), orderField, order);
              return order === Order.asc
                ? [...orderedNumberItems, ...orderedStringItems]
                : [...orderedStringItems, ...orderedNumberItems];
            },
          } : {}),
        } as ColumnDefinition))
        .concat([
          // this column is needed to stretch out if columns do not take up all of table's width
          {
            property: FLEX_FIELD,
            title: '',
            sortable: false,
          },
          {
            property: '',
            title: '',
            width: '40px',
            sortable: false,
            cellClass: classes.actionsCell,
            // TODO: Declare this component outside parent component "EditGridAdapter" or memoize it.
            //  If you want to allow component creation in props, set allowAsProps option to true
            // eslint-disable-next-line react/no-unstable-nested-components
            Component: ({ item }) => {
              const index = displayList.indexOf(item);
              return (
                <EditGridActions
                  additionalActions={component.rowActions || []}
                  onAction={this.onAction(index)}
                  onDeleteClick={this.onDelete(index)}
                  onEditClick={this.onSelect(index, SelectionMode.edit)}
                  onViewClick={this.onSelect(index, SelectionMode.view)}
                  deleteButtonText={textLabels.delete}
                  editButtonText={textLabels.edit}
                  viewButtonText={textLabels.view}
                  deleteButtonDisabled={
                    disabled || component.disableAddingRemovingRows || !this.allowRemovingItems()
                  }
                  editButtonDisabled={disabled}
                  readOnly={component.readOnly}
                />
              );
            },
          },
        ] as ColumnDefinition[]),
    );
    const isSearchDone = displayList.length !== filteredDisplayList.length;
    return (
      <div data-xpath={name} className={classes.root}>
        {component.quickSearch
          && (
            <SearchInput
              onSearch={this.handleChangeSearch}
              placeholder={textLabels.searchPlaceholder}
              label={textLabels.searchLabel}
              className={classes.searchInput}
              clearIcon
              disableSearchIcon
            />
          )}
        <Table
          columnDefinitions={columnDefinitions}
          list={filteredDisplayList}
          header={{
            'data-xpath': 'editGrid-table-header',
          }}
          emptyPlaceholder={isSearchDone ? textLabels.searchEmptyPlaceholder : textLabels.emptyPlaceholder}
          emptyPlaceholderIcon=""
          emptyPlaceholderInline
          pagination={{
            totalItems: editGridValue.length,
            getDisplayedRowsLabel: textLabels.getDisplayedRowsLabel,
            labelRowsPerPage: textLabels.rowsPerPage,
            ariaLabel: textLabels.paginationAriaLabel,
          }}
          classes={{
            root: classes.table,
            table: classes.tableInner,
            cell: classes.cell,
            row: classes.row,
          }}
          getRowClass={(item) => {
            const index = displayList.indexOf(item);
            return get(value, `${index}.${SELECTION_FIELD}`) && classes.selectedRow;
          }}
        />
        {
          !component.readOnly && (
            <InlineButton
              onLinkClick={this.onAdd}
              disabled={disabled || component.disableAddingRemovingRows || !this.allowAddingItems()}
              component="button"
              classes={{ link: classes.addButton }}
              leftIcon={<PlusIcon />}
              size="medium"
            >
              {component.addAnother || textLabels.add}
            </InlineButton>
          )
        }
        {component.description && <DescriptionBox description={component.description} />}
        {error && (
          <FieldError error={error} />
        )}
        <div style={{ display: 'none' }}>
          {
            filteredDisplayList.map((data, index) => (
              <div id={this.getFormId(index)} key={data.id} />
            ))
          }
        </div>
        <Modal
          isOpen={isSelected}
          onOpenChange={this.onSelectionChange}
          classes={{ paper: classes.modal }}
          modalzIndex={zIndex.speedDial + 1}
          title={selectionMode === SelectionMode.view ? textLabels.viewModalTitle : textLabels.editModalTitle}
          hasCloseBtn
        >
          {
            itemUnderSelection && (
              <div className={classes.form}>
                <Form
                  onSubmit={(data) => this.onRowSubmit(data, indexUnderSelection)}
                  language={language}
                  components={this.getModalComponents(components)}
                  submissionData={{ data: itemUnderSelection }}
                  onCustomEvent={this.onFormEvent}
                  parentPath={component.key}
                  evalContext={{
                    ...evalContext,
                    rowIndex: indexUnderSelection,
                  }}
                />
              </div>
            )
          }
        </Modal>
        <Modal
          isOpen={isNumber(indexUnderDeletion)}
          onOpenChange={this.onDeleteConfirmationChange}
          classes={{ paper: classes.modal }}
        >
          <div className={classes.deleteConfirmationRoot}>
            <Typography variant="h1">
              <TrashCanIcon />
            </Typography>
            <Typography variant="h2" className={classes.deleteModalTitle}>
              {textLabels.deleteConfirmationTitle}
            </Typography>
            <div>
              <Button
                onClick={() => this.onDeleteConfirmationChange(false)}
                className={classes.deleteModalButton}
              >
                {textLabels.deleteConfirmationNo}
              </Button>
              <Button
                onClick={this.onDeleteConfirmation}
                className={classes.deleteModalButton}
                variant={ButtonVariants.secondary}
              >
                {textLabels.deleteConfirmationYes}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default (withFormioControl(withStyles(styles)(EditGridAdapter)));
