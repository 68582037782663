import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import InlineButton from '#web-components/components/InlineButton';
import DescriptionBox from '#web-components/components/DescriptionBox';
import Typography from '#web-components/components/Typography';
import { CheckMarkIcon, PauseIcon, EditOutlinedIcon } from '#web-components/components/Icons';
import IconButton from '#web-components/components/IconButton';

import styles from './ChannelItem.styles';

const useStyles = makeStyles(styles, { name: 'ChannelItem' });

interface ChannelItemProps {
  label: string;
  icon: React.ElementType;
  hint: React.ReactNode | string;
  isActive: boolean;
  channel?: string;
  placeholder?: React.ReactNode | string;
  onEdit?: () => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
}

export default function ChannelItem({
  label,
  channel,
  placeholder,
  icon: Icon,
  hint,
  isActive,
  onEdit,
  onActivate,
  onDeactivate,
}: ChannelItemProps) {
  const classes = useStyles();
  const { t } = useTranslation('profilePage');

  return (
    <Box className={clsx(classes.root, channel && classes.borderBottom)}>
      <Typography variant="tinyText">{label}</Typography>
      <Box display="flex" className={classes.box}>

        {!channel && placeholder}

        {channel && (
          <>
            <Box className={clsx(classes.icon, !isActive && classes.iconDisabled)}><Icon /></Box>
            <Box className={classes.boxChannel}>
              <Box className={classes.channel}>
                <Typography
                  variant="h5"
                  className={clsx(classes.channelName, !isActive && classes.channelNameNonActive)}
                >
                  {channel}
                </Typography>
                {onEdit && <IconButton className={classes.editIcon} onClick={onEdit}><EditOutlinedIcon /></IconButton>}
              </Box>

              {!isActive && (
                <Box className={classes.hint}>
                  <DescriptionBox
                    description={hint}
                  />
                </Box>
              )}

              <InlineButton
                size="medium"
                onLinkClick={isActive ? onDeactivate : onActivate}
                leftIcon={isActive ? <PauseIcon /> : <CheckMarkIcon />}
              >
                {isActive ? t('settings.turnOffMessage') : t('settings.allowMessages')}
              </InlineButton>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
