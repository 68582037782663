import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';
import { formatFormSubmissionToTaskData, formatTaskDataToFormSubmission } from 'utils/formData';

import {
  createAsyncActionErrorSelector,
  createAsyncActionValidationErrorsSelector,
  createAsyncActionIsLoadingSelector,
} from '../asyncAction';
import {
  getTaskRequest, completeTaskRequest, signTaskRequest, getPendingTaskRequest, saveTaskRequest,
} from './slice';

export const selectUserTaskState = (state: RootState) => state.userTask;
export const selectUserTask = createSelector(
  selectUserTaskState,
  (state) => state.data,
);
export const selectUserTaskForm = createSelector(
  selectUserTaskState,
  (state) => state.form,
);

export const selectUserTaskFormSubmissionData = createSelector(
  selectUserTask,
  (task) => formatTaskDataToFormSubmission(task?.data),
);

export const selectFormVariables = createSelector(
  selectUserTaskFormSubmissionData,
  (taskSubmissionData) => formatFormSubmissionToTaskData(taskSubmissionData).data,
);

export const selectUserTaskIsLoading = createSelector(
  createAsyncActionIsLoadingSelector(getTaskRequest.type),
  createAsyncActionIsLoadingSelector(completeTaskRequest.type),
  createAsyncActionIsLoadingSelector(signTaskRequest.type),
  createAsyncActionIsLoadingSelector(saveTaskRequest.type),
  (
    getLoading,
    completeLoading,
    signLoading,
    saveLoading,
  ) => getLoading || completeLoading || signLoading || saveLoading,
);

export const selectPendingTaskIsLoading = createAsyncActionIsLoadingSelector(getPendingTaskRequest.type);
export const selectPendingTaskError = createAsyncActionErrorSelector(getPendingTaskRequest.type);

export const selectUserTaskError = (
  createAsyncActionErrorSelector(getTaskRequest.type)
);

export const selectUserTaskValidationErrors = (
  createAsyncActionValidationErrorsSelector(completeTaskRequest.type)
);

export const selectSignUserTaskValidationErrors = (
  createAsyncActionValidationErrorsSelector(signTaskRequest.type)
);
