import { createStyles } from '@material-ui/core';

import { spacing } from '#web-components/styles';

const styles = () => createStyles({
  notificationsContainer: {
    paddingBottom: spacing * 4,
    maxWidth: spacing * 92,
  },
});

export default styles;
