import { createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { spacing } from '#web-components/styles';

const styles = ({ colors }: Theme) => createStyles({
  root: {
    paddingBottom: (spacing * 5) - 2,
    marginBottom: spacing * 5,
  },
  borderBottom: {
    borderBottom: `2px solid ${colors.uIBase4}`,
  },
  box: {
    marginTop: spacing,
  },
  boxChannel: {
    width: '100%',
  },
  icon: {
    marginRight: spacing,
    '& svg': {
      fill: colors.uIIconBase,
    },
  },
  iconDisabled: {
    '& svg': {
      fill: colors.uIIconDisabled,
    },
  },
  channel: {
    marginBottom: spacing * 3,
    position: 'relative',
  },
  channelName: {
    display: 'flex',
  },
  channelNameNonActive: {
    color: colors.textMainSecondary,
  },
  editIcon: {
    position: 'absolute',
    top: '-6px',
    right: 0,
  },
  hint: {
    marginBottom: (spacing * 4) - 4,
  },
});

export default styles;
