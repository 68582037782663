import baseDateTimeForm from 'formiojs/components/datetime/DateTime.form';
import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultValidationFields,
  defaultDataFields,
  tableSettingsTab,
  defaultApiFields,
} from '#web-components/components/Form/constants';

export default () => {
  return baseDateTimeForm([
    {
      key: 'display',
      components: ignoreBuilderFields([
        'displayInTimezone',
        'useLocaleSettings',
        'shortcutButtons',
        'widget.type',
        'widget',
        'customClass',
        'autofocus',
        'modalEdit',
        'hideLabel',
        'tableView',
      ]),
    },
    {
      key: 'data',
      components: [
        ...ignoreBuilderFields([
          'defaultDate',
          'multiple',
          'customOptions',
          'redrawOn',
          'persistent',
          'dbIndex',
          'encrypted',
          'protected',
          'calculateServer',
        ]),
        ...defaultDataFields,
      ],
    },
    {
      key: 'validation',
      components: [
        ...defaultValidationFields,
        ...ignoreBuilderFields(['errorLabel', 'json-validation-json', 'unique']),
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    tableSettingsTab,
  ]);
};
