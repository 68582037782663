import baseFileForm from 'formiojs/components/file/File.form';
import bytes from 'bytes';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import { ignoreBuilderFields } from '#web-components/utils';
import {
  defaultConditionalFields,
  defaultDataFields,
  defaultValidationFields,
  defaultApiFields,
  tableSettingsTab,
} from '#web-components/components/Form/constants';

export const DEFAULT_MAX_FILE_SIZE = '100MB';
const REGEX_PATTERN = '^\\d+(\\.\\d+)?\\s*(b|kb|mb|gb|B|KB|MB|GB)?$';

const validateFileSize = (context: unknown, path: string, errorMessage = 'moreThanMaxValue') => {
  const maxFileSize = get(context, path, DEFAULT_MAX_FILE_SIZE);
  const input = get(context, 'input');

  if (!input.match(REGEX_PATTERN)) {
    return 'checkFieldFormat';
  }

  return bytes(maxFileSize) >= bytes(input) ? true : errorMessage;
};

const getCustomDefaultValueFileSize = (context: unknown) => {
  const key = get(context, 'component.key');
  const maxFileSize = get(context, `instance.options[${key}]`, DEFAULT_MAX_FILE_SIZE);
  return maxFileSize;
};

/**
 * This function returns the fields that should be present in the builder
 * You can extend the default one of any component
 * The values you pass are merged with the passed ones using the function unifyComponents (seacrh in formio.js source)
 *
 * *overrideEditForm* param is used when component key is equal to the default one to favor yours
 * *ignore* param will force the skipping of this component
 */
export default () => {
  return baseFileForm([
    {
      key: 'display',
      components: ignoreBuilderFields([
        'customClass',
        'modalEdit',
        'hideLabel',
        'tableView',
      ]),
    },
    {
      key: 'file',
      components: [
        ...ignoreBuilderFields([
          'storage',
          'fileTypes',
          'dir',
          'fileNameTemplate',
          'webcam',
          'webcamSize',
        ]),
        {
          overrideEditForm: true,
          key: 'url',
          conditional: {
            json: { '===': [{ var: 'data.storageProvider' }, 'custom'] },
          },
          allowCalculateOverride: true,
          calculateValue: 'value = data.storageProvider === "digital-document-service" ? "/documents" : "";',
          clearOnHide: false,
        },
        {
          overrideEditForm: true,
          key: 'options',
          conditional: {},
        },
        {
          overrideEditForm: true,
          key: 'fileKey',
          conditional: {},
        },
        {
          overrideEditForm: true,
          key: 'privateDownload',
          conditional: {},
        },
        {
          type: 'select',
          hidden: true,
          clearOnHide: false,
          input: true,
          key: 'storageProvider',
          label: 'Storage',
          placeholder: 'Select your file storage provider',
          weight: 0,
          tooltip: 'Which storage to save the files in.',
          valueProperty: 'value',
          defaultValue: 'digital-document-service',
          dataSrc: 'values',
          data: {
            values: [
              {
                value: 'digital-document-service',
                label: 'Digital-document-service',
              },
              {
                value: 'custom',
                label: 'Custom Url',
              },
            ],
          },
        },
        {
          key: 'fileMinSize',
          tooltip: 'tooltipFileMinSize',
          validate: {
            required: true,
            custom: (context: { data: { multiple: boolean } }) => {
              let valid = validateFileSize(context, 'data.fileMaxSize');
              if (context.data.multiple && isBoolean(valid)) {
                valid = validateFileSize(context, 'data.fileMinTotalSize');
              }
              return valid;
            },
          },
          overrideEditForm: true,
        },
        {
          key: 'fileMaxSize',
          tooltip: 'tooltipFileMaxSize',
          validate: {
            required: true,
            custom: (context: { data: { multiple: boolean } }) => {
              let valid = validateFileSize(
                context,
                'instance.options.fileMaxSize',
                'moreThanMaxValueSetByAdministrator',
              );
              if (context.data.multiple && isBoolean(valid)) {
                valid = validateFileSize(context, 'data.fileMaxTotalSize');
              }
              return valid;
            },
          },
          customDefaultValue: getCustomDefaultValueFileSize,
          overrideEditForm: true,
        },
        {
          type: 'textfield',
          input: true,
          key: 'fileMinTotalSize',
          label: 'Minimum total size',
          placeholder: '1MB',
          tooltip: 'tooltipFileMinTotalSize',
          weight: 71,
          conditional: {
            json: {
              '==': [{
                var: 'data.multiple',
              }, true],
            },
          },
          validate: {
            required: true,
            custom: (context: unknown) => validateFileSize(context, 'data.fileMaxTotalSize'),
          },
        },
        {
          type: 'textfield',
          input: true,
          key: 'fileMaxTotalSize',
          label: 'Maximum total size',
          placeholder: '100MB',
          tooltip: 'tooltipFileMaxTotalSize',
          weight: 72,
          conditional: {
            json: {
              '==': [{
                var: 'data.multiple',
              }, true],
            },
          },
          validate: {
            required: true,
            custom: (context: unknown) => validateFileSize(
              context,
              'instance.options.fileMaxTotalSize',
              'moreThanMaxValueSetByAdministrator',
            ),
          },
          defaultValue: DEFAULT_MAX_FILE_SIZE,
          customDefaultValue: getCustomDefaultValueFileSize,
        },
      ],
    },
    {
      key: 'data',
      components: [
        ...ignoreBuilderFields([
          'persistent',
          'protected',
          'dbIndex',
          'redrawOn',
          'encrypted',
          'calculateServer',
          'allowCalculateOverride',
        ]),
        ...defaultDataFields,
      ],
    },
    {
      key: 'validation',
      components: [
        ...ignoreBuilderFields(['errorLabel', 'json-validation-json']),
        {
          type: 'textfield',
          key: 'resourceValidation',
          label: 'Resource for validation',
          input: true,
          weight: 100,
          validate: {
            required: true,
          },
          customConditional(data: { row: { filePattern: string; }; }) {
            return data.row.filePattern.endsWith('.csv');
          },
        },
        ...defaultValidationFields,
      ],
    },
    {
      key: 'api',
      components: [
        ...ignoreBuilderFields(['tags', 'properties']),
        ...defaultApiFields,
      ],
    },
    {
      key: 'logic',
      ignore: true,
    },
    {
      key: 'layout',
      ignore: true,
    },
    {
      key: 'conditional',
      components: defaultConditionalFields,
    },
    tableSettingsTab,
  ]);
};
