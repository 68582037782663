import { AjaxError } from 'rxjs/ajax';
import i18n from 'localization';
import get from 'lodash/get';
import { ERROR_TYPE, ErrorInfo } from '#shared/types/common';
import { FormSubmission } from 'types/form';
import { TaskSubmissionPayload, TaskData } from '#shared/types/task';
import { Form } from '#web-components/components/Form/types';
import { getServerValidationErrors } from '#shared/utils/apiHelpers';

export const formatFormSubmissionToTaskData = (
  submission: FormSubmission,
): TaskSubmissionPayload => {
  return {
    data: submission.data,
  };
};

export const formatTaskDataToFormSubmission = (
  data?: TaskData,
): FormSubmission => {
  return { data: data || {} };
};

export const createErrorMessage = (serverErrorMessage: string, keyTitle?: string): string => {
  const shouldMessageBeWithTitle = keyTitle && !serverErrorMessage.includes(keyTitle);
  return shouldMessageBeWithTitle ? `${keyTitle}: ${serverErrorMessage}` : serverErrorMessage;
};

export const getValidationErrors = (response: AjaxError, formData?: Form | null): Array<ErrorInfo> => {
  const errorCode = get(response.response, 'code', '');

  if (errorCode && i18n.exists(`errors.validation.${errorCode}`)) {
    return [{
      message: i18n.t(`errors.validation.${errorCode}`),
      type: ERROR_TYPE.VALIDATION,
    }];
  }

  const serverErrors = getServerValidationErrors(response);
  const components = formData?.components || [];

  return serverErrors.map((serverError) => {
    const contextComponent = components.find((component) => component.key === serverError.field);
    const keyTitle = contextComponent?.label;
    const message = createErrorMessage(serverError.message, keyTitle);

    return { message, type: ERROR_TYPE.VALIDATION };
  });
};
